import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Airtable from 'airtable'
import Text from '../../common/Text'
import Space from '../../common/Space'
import { persistData, getPersistedData } from '../../../utils/localStorageHelpers'

// Airtable columns
const labelColName = 'Label'

// BS table column names
const q42020ColName = '2020'
const q42019ColName = '2019'

// IS/Non-GAAP table columns
const threeMonth2020ColName = 'three month 2020'
const threeMonth2019ColName = 'three month 2019'
const yearEnded2020ColName = 'year ended 2020'
const yearEnded2019ColName = 'year ended 2019'

// configure Airtable
Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: process.env.GATSBY_AIRTABLE_API_KEY,
})
const AIRTABLE_BASE = Airtable.base('app0PONA9e9MwmfGS')

const StyledTable = styled.table`
  width: 100%;
  font-family: ${p => p.theme.fonts.family.body};
  margin: 0 auto;

  caption {
    font-weight: 700;
    margin-bottom: 1.5em;
  }
  .text-weight-book {
    font-weight: 400 !important;
  }
  td,
  th {
    text-align: left;
    border-bottom: solid 1px gray;
    padding: 0.3em;
  }
  td.w-60,
  th.w-60 {
    width: 60%;
  }
  td.text-align-right,
  th.text-align-right {
    text-align: right;
  }
  td.text-align-center,
  th.text-align-center {
    text-align: center;
  }
  .text-header {
    font-weight: 400;
  }

  .large-header {
    font-size: 1.2em;
  }
  .indent {
    padding-left: 1em;
  }
  .mr-2 {
    margin-right: 1em;
  }
  .mt-2 {
    margin-top: 1em;
  }
  .mt-4 {
    margin-top: 2em;
  }
  .border-top {
    border-top: solid 1px black;
  }
  .no-border {
    border: none;
  }
  .subtotal,
  .total,
  .total2 {
    position: relative;
  }
  .subtotal:before {
    content: '';
    background: black;
    position: absolute;
    top: -4px;
    right: 0;
    height: 1px;
    width: 75%;
  }
  .subtotal.full-width:before {
    width: 100%;
  }
  .subtotal.space-between {
    display: flex;

    div {
      width: 50%;
      text-align: right;
      border-bottom: 1px solid black;

      &:not(:first-of-type) {
        margin-left: 12px;
      }
    }
  }
  .total:after {
    content: '';
    background: black;
    position: absolute;
    bottom: -4px;
    right: 0;
    height: 1px;
    width: 75%;
  }
  .total2:after {
    content: '';
    background: black;
    position: absolute;
    bottom: -7px;
    right: 0;
    height: 1px;
    width: 75%;
  }
  .spacer {
    padding: 0.8em;
  }
  .spacer-row-subtotal {
    .subtotal {
      height: 19px;
    }
  }
  .no-wrap {
    white-space: nowrap;
  }
`

const formatCurrency = currency =>
  currency ? currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : <span>&mdash;</span>

const BsTable = data => {
  const bsData = data.data

  return (
    <StyledTable
      summary="Column one has the asset or liability, other columns show the amounts by date period"
      id="assets_liabilities"
      className="financial-table mt-4"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <thead>
        <tr>
          <td className="no-border" />
          <th colSpan="2" id="threemonths" scope="col" className="text-align-center no-wrap no-border">
            December 31,
          </th>
        </tr>
        <tr>
          <td className="no-border" />
          <th scope="col" colSpan="2" className="no-border no-wrap">
            <div className="subtotal full-width space-between">
              <div style={{ textAlign: 'center' }}>2020</div>
              <div style={{ textAlign: 'center' }}>2019</div>
            </div>
          </th>
        </tr>
        <tr>
          <td colSpan="3" className="text-align-left">
            ( In thousands, except per share data )
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th id="assets" colSpan="3" scope="colgroup" className="large-header">
            ASSETS
          </th>
        </tr>
        <tr>
          <th id="current_assets" colSpan="3" scope="colgroup" headers="assets" className="indent">
            Current assets:
          </th>
        </tr>

        {bsData.Cash && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Cash
            </th>
            <td headers="assets current_assets" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(bsData.Cash[q42020ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(bsData.Cash[q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Accounts receivable, net of allowance for doubtful accounts of $79 and $63, respectively'] && (
          <tr>
            <th headers="assets current_assets" id="arn" className="text-header indent">
              Accounts receivable, net of allowance for doubtful accounts of $79 and $63, respectively
            </th>
            <td headers="assets current_assets arn " className="text-align-right">
              {formatCurrency(
                bsData['Accounts receivable, net of allowance for doubtful accounts of $79 and $63, respectively'][
                  q42020ColName
                ]
              )}
            </td>
            <td headers="assets current_assets arn " className="text-align-right">
              {formatCurrency(
                bsData['Accounts receivable, net of allowance for doubtful accounts of $79 and $63, respectively'][
                  q42019ColName
                ]
              )}
            </td>
          </tr>
        )}

        {bsData['Unbilled receivables'] && (
          <tr>
            <th headers="assets current_assets" id="ur" className="text-header indent">
              Unbilled receivables
            </th>
            <td headers="assets current_assets ur " className="text-align-right">
              {formatCurrency(bsData['Unbilled receivables'][q42020ColName])}
            </td>
            <td headers="assets current_assets ur " className="text-align-right">
              {formatCurrency(bsData['Unbilled receivables'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Deferred costs, short term'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Deferred costs, short term
            </th>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Deferred costs, short term'][q42020ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Deferred costs, short term'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Debt issuance costs, net'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Debt issuance costs, net
            </th>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Debt issuance costs, net'][q42020ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Debt issuance costs, net'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Prepaid expenses and other current assets'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Prepaid expenses and other current assets
            </th>
            <td headers="assets current_assets" className="text-align-right no-border">
              {formatCurrency(bsData['Prepaid expenses and other current assets'][q42020ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right no-border">
              {formatCurrency(bsData['Prepaid expenses and other current assets'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Total current assets'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Total current assets
            </th>
            <td headers="assets current_assets" className="text-align-right">
              <div className="subtotal">{formatCurrency(bsData['Total current assets'][q42020ColName])}</div>
            </td>
            <td headers="assets current_assets" className="text-align-right">
              <div className="subtotal">{formatCurrency(bsData['Total current assets'][q42019ColName])}</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        {bsData['Property and equipment, net of accumulated depreciation of $209 and $124, respectively'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Property and equipment, net of accumulated depreciation of $209 and $124, respectively
            </th>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(
                bsData['Property and equipment, net of accumulated depreciation of $209 and $124, respectively'][
                  q42020ColName
                ]
              )}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(
                bsData['Property and equipment, net of accumulated depreciation of $209 and $124, respectively'][
                  q42019ColName
                ]
              )}
            </td>
          </tr>
        )}

        {bsData['Right of use assets'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Right of use assets
            </th>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Right of use assets'][q42020ColName])}
            </td>
            <td headers="assets current_assets" className="text-align-right">
              {formatCurrency(bsData['Right of use assets'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Deferred costs, long term'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Deferred costs, long term
            </th>
            <td headers="assets current_assets  " className="text-align-right">
              {formatCurrency(bsData['Deferred costs, long term'][q42020ColName])}
            </td>
            <td headers="assets current_assets  " className="text-align-right">
              {formatCurrency(bsData['Deferred costs, long term'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Intangible assets, net of accumulated amortization of $4,328 and $3,710, respectively'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Intangible assets, net of accumulated amortization of $4,328 and $3,710, respectively
            </th>
            <td headers="assets current_assets ian " className="text-align-right">
              {formatCurrency(
                bsData['Intangible assets, net of accumulated amortization of $4,328 and $3,710, respectively'][
                  q42020ColName
                ]
              )}
            </td>
            <td headers="assets current_assets ian " className="text-align-right">
              {formatCurrency(
                bsData['Intangible assets, net of accumulated amortization of $4,328 and $3,710, respectively'][
                  q42019ColName
                ]
              )}
            </td>
          </tr>
        )}

        {bsData.Goodwill && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Goodwill
            </th>
            <td headers="assets current_assets  " className="text-align-right no-border">
              {formatCurrency(bsData.Goodwill[q42020ColName])}
            </td>
            <td headers="assets current_assets  " className="text-align-right no-border">
              {formatCurrency(bsData.Goodwill[q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Total assets'] && (
          <tr>
            <th headers="assets current_assets" className="text-header indent">
              Total assets
            </th>
            <td headers="assets current_assets" className="text-align-right no-border">
              <div className="subtotal">
                <div className="total">
                  <div className="total2">
                    <span className="mr-2">$</span>
                    {formatCurrency(bsData['Total assets'][q42020ColName])}
                  </div>
                </div>
              </div>
            </td>
            <td headers="assets current_assets ta " className="text-align-right no-border">
              <div className="subtotal">
                <div className="total">
                  <div className="total2">
                    <span className="mr-2">$</span>
                    {formatCurrency(bsData['Total assets'][q42019ColName])}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="liabilities" colSpan="3" scope="colgroup" className="large-header">
            LIABILITIES AND STOCKHOLDERS' EQUITY
          </th>
        </tr>
        <tr>
          <th id="current_liabilities" colSpan="3" scope="colgroup" headers="liabilities" className="indent">
            Current liabilities:
          </th>
        </tr>

        {bsData['Accounts payable and accrued expenses'] && (
          <tr>
            <th headers="liabilities current_liabilities" className="text-header indent">
              Accounts payable and accrued expenses
            </th>
            <td headers="liabilities current_liabilities apaae " className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(bsData['Accounts payable and accrued expenses'][q42020ColName])}
            </td>
            <td headers="liabilities current_liabilities apaae " className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(bsData['Accounts payable and accrued expenses'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Finance lease liabilities'] && (
          <tr>
            <th headers="liabilities current_liabilities" className="text-header indent">
              Finance lease liabilities
            </th>
            <td headers="liabilities current_liabilities fll " className="text-align-right">
              {formatCurrency(bsData['Finance lease liabilities'][q42020ColName])}
            </td>
            <td headers="liabilities current_liabilities fll " className="text-align-right">
              {formatCurrency(bsData['Finance lease liabilities'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Operating lease liabilities'] && (
          <tr>
            <th headers="liabilities current_liabilities" className="text-header indent">
              Operating lease liabilities
            </th>
            <td headers="liabilities current_liabilities oll " className="text-align-right">
              {formatCurrency(bsData['Operating lease liabilities'][q42020ColName])}
            </td>
            <td headers="liabilities current_liabilities oll " className="text-align-right">
              {formatCurrency(bsData['Operating lease liabilities'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Warrant liability'] && (
          <tr>
            <th headers="liabilities current_liabilities" className="text-header indent">
              Warrant liability
            </th>
            <td headers="liabilities current_liabilities wl " className="text-align-right">
              {formatCurrency(bsData['Warrant liability'][q42020ColName])}
            </td>
            <td headers="liabilities current_liabilities wl " className="text-align-right">
              {formatCurrency(bsData['Warrant liability'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Deferred revenue'] && (
          <tr>
            <th headers="liabilities current_liabilities" className="text-header indent">
              Deferred revenue
            </th>
            <td headers="liabilities current_liabilities dr " className="text-align-right">
              {formatCurrency(bsData['Deferred revenue'][q42020ColName])}
            </td>
            <td headers="liabilities current_liabilities dr " className="text-align-right">
              {formatCurrency(bsData['Deferred revenue'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Term loan, short term'] && (
          <tr>
            <th headers="term loan short term" className="text-header indent">
              Term loan, short term
            </th>
            <td headers="term loan short term" className="text-align-right no-border">
              {formatCurrency(bsData['Term loan, short term'][q42020ColName])}
            </td>
            <td headers="term loan short term" className="text-align-right no-border">
              {formatCurrency(bsData['Term loan, short term'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Total current liabilities'] && (
          <tr>
            <th headers="liabilities current_liabilities" className="text-header indent">
              Total current liabilities
            </th>
            <td headers="liabilities current_liabilities tcl " className="text-align-right">
              <div className="subtotal" />
              {formatCurrency(bsData['Total current liabilities'][q42020ColName])}
            </td>
            <td headers="liabilities current_liabilities tcl " className="text-align-right">
              <div className="subtotal" />
              {formatCurrency(bsData['Total current liabilities'][q42019ColName])}
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="longterm_liabilities" colSpan="3" scope="colgroup" headers="liabilities" className="indent">
            Long term liabilities:
          </th>
        </tr>

        {bsData['Finance lease liabilities_2'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" className="text-header indent">
              Finance lease liabilities
            </th>
            <td headers="liabilities longterm_liabilities fllll " className="text-align-right">
              {formatCurrency(bsData['Finance lease liabilities_2'][q42020ColName])}
            </td>
            <td headers="liabilities longterm_liabilities fllll " className="text-align-right">
              {formatCurrency(bsData['Finance lease liabilities_2'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Operating lease liabilities_2'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" className="text-header indent">
              Operating lease liabilities
            </th>
            <td headers="liabilities longterm_liabilities ollll " className="text-align-right">
              {formatCurrency(bsData['Operating lease liabilities_2'][q42020ColName])}
            </td>
            <td headers="liabilities longterm_liabilities ollll " className="text-align-right">
              {formatCurrency(bsData['Operating lease liabilities_2'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Deferred revenue_2'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" className="text-header indent">
              Deferred revenue
            </th>
            <td headers="liabilities longterm_liabilities drll " className="text-align-right">
              {formatCurrency(bsData['Deferred revenue_2'][q42020ColName])}
            </td>
            <td headers="liabilities longterm_liabilities drll " className="text-align-right">
              {formatCurrency(bsData['Deferred revenue_2'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Term loan, long term'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" className="text-header indent">
              Term loan, long term
            </th>
            <td headers="liabilities longterm_liabilities lpll " className="text-align-right no-border">
              {formatCurrency(bsData['Term loan, long term'][q42020ColName])}
            </td>
            <td headers="liabilities longterm_liabilities lpll " className="text-align-right no-border">
              {formatCurrency(bsData['Term loan, long term'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Total liabilities'] && (
          <tr>
            <th headers="liabilities" id="tl" className="text-header indent">
              Total liabilities
            </th>
            <td headers="liabilities tl " className="text-align-right">
              <div className="subtotal">{formatCurrency(bsData['Total liabilities'][q42020ColName])}</div>
            </td>
            <td headers="liabilities tl " className="text-align-right">
              <div className="subtotal">{formatCurrency(bsData['Total liabilities'][q42019ColName])}</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="stockholders_equity" scope="colgroup" colSpan="3" headers="liabilities" className="indent">
            <div>Stockholders' equity:</div>
            <div className="text-weight-book">Preferred stock, $0.00001 par value, 10,000 shares authorized</div>
          </th>
        </tr>

        {bsData[
          'Series A Convertible Preferred stock, $0.00001 par value, 200 shares authorized, 90 and 105 shares issued and outstanding as of December 31, 2020 and 2019, respectively'
        ] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="seriesa" className="text-header indent w-60">
              <div>Series A Convertible Preferred stock,</div>
              <div className="text-weight-book">
                $0.00001 par value, 200 shares authorized, 90 and 105 shares issued and outstanding as of December 31,
                2020 and 2019, respectively
              </div>
            </th>
            <td headers="liabilities stockholders_equity seriesa " className="text-align-right">
              {formatCurrency(
                bsData[
                  'Series A Convertible Preferred stock, $0.00001 par value, 200 shares authorized, 90 and 105 shares issued and outstanding as of December 31, 2020 and 2019, respectively'
                ][q42020ColName]
              )}
            </td>
            <td headers="liabilities stockholders_equity seriesa " className="text-align-right">
              {formatCurrency(
                bsData[
                  'Series A Convertible Preferred stock, $0.00001 par value, 200 shares authorized, 90 and 105 shares issued and outstanding as of December 31, 2020 and 2019, respectively'
                ][q42019ColName]
              )}
            </td>
          </tr>
        )}

        {bsData[
          'Common stock, $0.00001 par value, 50,000 shares authorized, 10,130 and 8,877 shares issued and outstanding as of December 31, 2020 and 2019, respectively'
        ] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="common" className="text-header indent w-60">
              <div>Common stock,</div>
              <div className="text-weight-book">
                $0.00001 par value, 50,000 shares authorized, 10,130 and 8,877 shares issued and outstanding as of
                December 31, 2020 and 2019, respectively
              </div>
            </th>
            <td headers="liabilities stockholders_equity common " className="text-align-right">
              {formatCurrency(
                bsData[
                  'Common stock, $0.00001 par value, 50,000 shares authorized, 10,130 and 8,877 shares issued and outstanding as of December 31, 2020 and 2019, respectively'
                ][q42020ColName]
              )}
            </td>
            <td headers="liabilities stockholders_equity common " className="text-align-right">
              {formatCurrency(
                bsData[
                  'Common stock, $0.00001 par value, 50,000 shares authorized, 10,130 and 8,877 shares issued and outstanding as of December 31, 2020 and 2019, respectively'
                ][q42019ColName]
              )}
            </td>
          </tr>
        )}

        {bsData['Additional paid-in capital'] && (
          <tr>
            <th headers="liabilities stockholders_equity" className="text-header indent">
              Additional paid-in capital
            </th>
            <td headers="liabilities stockholders_equity apc " className="text-align-right">
              {formatCurrency(bsData['Additional paid-in capital'][q42020ColName])}
            </td>
            <td headers="liabilities stockholders_equity apc " className="text-align-right">
              {formatCurrency(bsData['Additional paid-in capital'][q42019ColName])}
            </td>
          </tr>
        )}

        {bsData['Accumulated deficit'] && (
          <tr>
            <th headers="liabilities stockholders_equity" className="text-header indent">
              Accumulated deficit
            </th>
            <td
              headers="liabilities stockholders_equity ad "
              className="text-align-right no-border"
              aria-label="negative value"
            >
              ({formatCurrency(bsData['Accumulated deficit'][q42020ColName])})
            </td>
            <td
              headers="liabilities stockholders_equity ad "
              className="text-align-right no-border"
              aria-label="negative value"
            >
              ({formatCurrency(bsData['Accumulated deficit'][q42019ColName])})
            </td>
          </tr>
        )}

        {bsData["Total stockholders' equity"] && (
          <tr>
            <th headers="liabilities stockholders_equity" className="text-header indent">
              Total stockholders' equity
            </th>
            <td headers="liabilities stockholders_equity tse " className="text-align-right no-border">
              <div className="subtotal">{formatCurrency(bsData["Total stockholders' equity"][q42020ColName])}</div>
            </td>
            <td headers="liabilities stockholders_equity tse " className="text-align-right no-border">
              <div className="subtotal">{formatCurrency(bsData["Total stockholders' equity"][q42019ColName])}</div>
            </td>
          </tr>
        )}

        <tr className="spacer-row-subtotal">
          <td role="presentation" />
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
        </tr>

        {bsData["Total liabilities and stockholders' equity"] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="tlase" className="text-header indent">
              Total liabilities and stockholders' equity
            </th>
            <td headers="liabilities stockholders_equity tlase " className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>
                  {formatCurrency(bsData["Total liabilities and stockholders' equity"][q42020ColName])}
                </div>
              </div>
            </td>
            <td headers="liabilities stockholders_equity tlase " className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>
                  {formatCurrency(bsData["Total liabilities and stockholders' equity"][q42019ColName])}
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  )
}

const IsTable = data => {
  const isData = data.data

  return (
    <StyledTable
      summary="Column one has the revenue source, other columns show the amounts by date period"
      id="ae_revenue"
      className="financial-table"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <thead>
        <tr>
          <td className="no-border" />
          <th colSpan="2" id="threemonths" scope="col" className="text-align-center no-wrap no-border">
            Three months ended
            <br />
            December 31,
          </th>
          <th colSpan="2" id="ninemonths" scope="col" className="text-align-center no-wrap no-border">
            Year ended
            <br />
            December 31,
          </th>
        </tr>
        <tr>
          <td className="no-border" />
          <th id="3m2020" scope="col" colSpan="2" className="no-border no-wrap">
            <div className="subtotal full-width space-between">
              <div style={{ textAlign: 'center' }}>2020</div>
              <div style={{ textAlign: 'center' }}>2019</div>
            </div>
          </th>
          <th id="9m2020" scope="col" colSpan="2" className="no-border no-wrap">
            <div className="subtotal full-width space-between">
              <div style={{ textAlign: 'center' }}>2020</div>
              <div style={{ textAlign: 'center' }}>2019</div>
            </div>
          </th>
        </tr>
        <tr>
          <td colSpan="5" className="text-align-left">
            ( In thousands, except per share data )
          </td>
        </tr>
      </thead>
      <tbody>
        {isData.Revenue && (
          <tr>
            <th id="revenue" className="text-header">
              Revenue
            </th>
            <td headers="threemonths revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(isData.Revenue[threeMonth2020ColName])}
            </td>
            <td headers="threemonths revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(isData.Revenue[threeMonth2019ColName])}
            </td>
            <td headers="threemonths revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(isData.Revenue[yearEnded2020ColName])}
            </td>
            <td headers="threemonths 3m2019 revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(isData.Revenue[yearEnded2019ColName])}
            </td>
          </tr>
        )}

        {isData['Cost of revenue'] && (
          <tr>
            <th id="costofrevenue" className="text-header">
              Cost of revenue
            </th>
            <td headers="threemonths  costofrevenue" className="text-align-right no-border">
              {formatCurrency(isData['Cost of revenue'][threeMonth2020ColName])}
            </td>
            <td headers="threemonths 3m2019 costofrevenue" className="text-align-right no-border">
              {formatCurrency(isData['Cost of revenue'][threeMonth2019ColName])}
            </td>
            <td headers="threemonths  costofrevenue" className="text-align-right no-border">
              {formatCurrency(isData['Cost of revenue'][yearEnded2020ColName])}
            </td>
            <td headers="threemonths 3m2019 costofrevenue" className="text-align-right no-border">
              {formatCurrency(isData['Cost of revenue'][yearEnded2019ColName])}
            </td>
          </tr>
        )}

        {isData['Gross profit'] && (
          <tr>
            <th id="grossprofit" className="text-header">
              Gross profit
            </th>
            <td headers="threemonths  grossprofit" className="text-align-right">
              <div className="subtotal">{formatCurrency(isData['Gross profit'][threeMonth2020ColName])}</div>
            </td>
            <td headers="threemonths 3m2019 grossprofit" className="text-align-right">
              <div className="subtotal">{formatCurrency(isData['Gross profit'][threeMonth2019ColName])}</div>
            </td>
            <td headers="threemonths  grossprofit" className="text-align-right">
              <div className="subtotal">{formatCurrency(isData['Gross profit'][yearEnded2020ColName])}</div>
            </td>
            <td headers="threemonths 3m2019 grossprofit" className="text-align-right">
              <div className="subtotal">{formatCurrency(isData['Gross profit'][yearEnded2019ColName])}</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>
        <tr>
          <th id="oe" colSpan="5" scope="colgroup" className="text-header">
            Operating expenses:
          </th>
        </tr>

        {isData['Selling and marketing'] && (
          <tr>
            <th id="sam" className="text-header">
              Selling and marketing
            </th>
            <td headers="threemonths  oe sam" className="text-align-right">
              {formatCurrency(isData['Selling and marketing'][threeMonth2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe sam" className="text-align-right">
              {formatCurrency(isData['Selling and marketing'][threeMonth2019ColName])}
            </td>
            <td headers="threemonths  oe sam" className="text-align-right">
              {formatCurrency(isData['Selling and marketing'][yearEnded2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe sam" className="text-align-right">
              {formatCurrency(isData['Selling and marketing'][yearEnded2019ColName])}
            </td>
          </tr>
        )}

        {isData['Research and development'] && (
          <tr>
            <th id="rad" className="text-header">
              Research and development
            </th>
            <td headers="threemonths  oe rad" className="text-align-right">
              {formatCurrency(isData['Research and development'][threeMonth2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe rad" className="text-align-right">
              {formatCurrency(isData['Research and development'][threeMonth2019ColName])}
            </td>
            <td headers="threemonths  oe rad" className="text-align-right">
              {formatCurrency(isData['Research and development'][yearEnded2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe rad" className="text-align-right">
              {formatCurrency(isData['Research and development'][yearEnded2019ColName])}
            </td>
          </tr>
        )}

        {isData['General and administrative'] && (
          <tr>
            <th id="ga" className="text-header">
              General and administrative
            </th>
            <td headers="threemonths  oe ga" className="text-align-right no-border">
              {formatCurrency(isData['General and administrative'][threeMonth2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe ga" className="text-align-right no-border">
              {formatCurrency(isData['General and administrative'][threeMonth2019ColName])}
            </td>
            <td headers="threemonths  oe ga" className="text-align-right no-border">
              {formatCurrency(isData['General and administrative'][yearEnded2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe ga" className="text-align-right no-border">
              {formatCurrency(isData['General and administrative'][yearEnded2019ColName])}
            </td>
          </tr>
        )}

        {isData['Total operating expenses'] && (
          <tr>
            <th id="toe" className="text-header">
              Total operating expenses
            </th>
            <td headers="threemonths  oe toe" className="text-align-right">
              <div className="subtotal">
                {formatCurrency(isData['Total operating expenses'][threeMonth2020ColName])}
              </div>
            </td>
            <td headers="threemonths 3m2019 oe toe" className="text-align-right">
              <div className="subtotal">
                {formatCurrency(isData['Total operating expenses'][threeMonth2019ColName])}
              </div>
            </td>
            <td headers="threemonths  oe toe" className="text-align-right">
              <div className="subtotal">{formatCurrency(isData['Total operating expenses'][yearEnded2020ColName])}</div>
            </td>
            <td headers="threemonths 3m2019 oe toe" className="text-align-right">
              <div className="subtotal">{formatCurrency(isData['Total operating expenses'][yearEnded2019ColName])}</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>

        {isData['Operating loss'] && (
          <tr>
            <th id="ol" className="text-header">
              Operating loss
            </th>
            <td headers="threemonths  oe ol" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Operating loss'][threeMonth2020ColName])})
            </td>
            <td headers="threemonths 3m2019 oe ol" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Operating loss'][threeMonth2019ColName])})
            </td>
            <td headers="threemonths  oe ol" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Operating loss'][yearEnded2020ColName])})
            </td>
            <td headers="threemonths 3m2019 oe ol" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Operating loss'][yearEnded2019ColName])})
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="oie" colSpan="5" scope="colgroup" className="text-header">
            Other income (expense):
          </th>
        </tr>

        {isData['Other income'] && (
          <tr>
            <th className="text-header">Other income</th>
            <td headers="threemonths  oe oie" className="text-align-right">
              {formatCurrency(isData['Other income'][threeMonth2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe oie" className="text-align-right">
              {formatCurrency(isData['Other income'][threeMonth2019ColName])}
            </td>
            <td headers="threemonths  oe oie" className="text-align-right">
              {formatCurrency(isData['Other income'][yearEnded2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe oie" className="text-align-right">
              {formatCurrency(isData['Other income'][yearEnded2019ColName])}
            </td>
          </tr>
        )}

        {isData['Change in fair value of warrant liability'] && (
          <tr>
            <th className="text-header">Change in fair value of warrant liability</th>
            <td headers="threemonths  oe oie" className="text-align-right">
              {formatCurrency(isData['Change in fair value of warrant liability'][threeMonth2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe oie" className="text-align-right">
              {formatCurrency(isData['Change in fair value of warrant liability'][threeMonth2019ColName])}
            </td>
            <td headers="threemonths  oe oie" className="text-align-right">
              {formatCurrency(isData['Change in fair value of warrant liability'][yearEnded2020ColName])}
            </td>
            <td headers="threemonths 3m2019 oe oie" className="text-align-right">
              {formatCurrency(isData['Change in fair value of warrant liability'][yearEnded2019ColName])}
            </td>
          </tr>
        )}

        {isData['Interest expense'] && (
          <tr>
            <th id="iein" className="text-header">
              Interest expense
            </th>
            <td headers="threemonths  oe iein" className="text-align-right no-border" aria-label="negative value">
              ({formatCurrency(isData['Interest expense'][threeMonth2020ColName])})
            </td>
            <td headers="threemonths 3m2019 oe iein" className="text-align-right no-border" aria-label="negative value">
              ({formatCurrency(isData['Interest expense'][threeMonth2019ColName])})
            </td>
            <td headers="threemonths  oe iein" className="text-align-right no-border" aria-label="negative value">
              ({formatCurrency(isData['Interest expense'][yearEnded2020ColName])})
            </td>
            <td headers="threemonths 3m2019 oe iein" className="text-align-right no-border" aria-label="negative value">
              ({formatCurrency(isData['Interest expense'][yearEnded2019ColName])})
            </td>
          </tr>
        )}

        {isData['Total other income (expense)'] && (
          <tr>
            <th id="toe" className="text-header">
              Total other income (expense)
            </th>
            <td headers="threemonths  oe toe" className="text-align-right" aria-label="negative value">
              <div className="subtotal">
                ({formatCurrency(isData['Total other income (expense)'][threeMonth2020ColName])})
              </div>
            </td>
            <td headers="threemonths 3m2019 oe toe" className="text-align-right">
              <div className="subtotal">
                {formatCurrency(isData['Total other income (expense)'][threeMonth2019ColName])}
              </div>
            </td>
            <td headers="threemonths  oe toe" className="text-align-right" aria-label="negative value">
              <div className="subtotal">
                ({formatCurrency(isData['Total other income (expense)'][yearEnded2020ColName])})
              </div>
            </td>
            <td headers="threemonths 3m2019 oe toe" className="text-align-right">
              <div className="subtotal">
                {formatCurrency(isData['Total other income (expense)'][yearEnded2019ColName])}
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>

        {isData['Net loss'] && (
          <tr>
            <th id="nl" className="text-header">
              Net loss
            </th>
            <td headers="threemonths  oe nl" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Net loss'][threeMonth2020ColName])})
            </td>
            <td headers="threemonths 3m2019 oe nl" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Net loss'][threeMonth2019ColName])})
            </td>
            <td headers="threemonths  oe nl" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Net loss'][yearEnded2020ColName])})
            </td>
            <td headers="threemonths 3m2019 oe nl" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Net loss'][yearEnded2019ColName])})
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {isData['Dividends on Series A Convertible Preferred Stock'] && (
          <tr>
            <th id="dosacps" className="text-header no-border">
              Dividends on Series A Convertible Preferred Stock
            </th>
            <td headers="threemonths  oe dosacps" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                ({formatCurrency(isData['Dividends on Series A Convertible Preferred Stock'][threeMonth2020ColName])})
              </div>
            </td>
            <td
              headers="threemonths 3m2019 oe dosacps"
              className="text-align-right no-border"
              aria-label="negative value"
            >
              <div className="total">
                ({formatCurrency(isData['Dividends on Series A Convertible Preferred Stock'][threeMonth2019ColName])})
              </div>
            </td>
            <td headers="threemonths  oe dosacps" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                ({formatCurrency(isData['Dividends on Series A Convertible Preferred Stock'][yearEnded2020ColName])})
              </div>
            </td>
            <td
              headers="threemonths 3m2019 oe dosacps"
              className="text-align-right no-border"
              aria-label="negative value"
            >
              <div className="total">
                ({formatCurrency(isData['Dividends on Series A Convertible Preferred Stock'][yearEnded2019ColName])})
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {isData['Net loss available to common stockholders'] && (
          <tr>
            <th id="nlatcs" className="text-header no-border">
              Net loss available to common stockholders
            </th>
            <td headers="threemonths  oe nlatcs" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss available to common stockholders'][threeMonth2020ColName])})
                </div>
              </div>
            </td>
            <td
              headers="threemonths 3m2019 oe nlatcs"
              className="text-align-right no-border"
              aria-label="negative value"
            >
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss available to common stockholders'][threeMonth2019ColName])})
                </div>
              </div>
            </td>
            <td headers="threemonths  oe nlatcs" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss available to common stockholders'][yearEnded2020ColName])})
                </div>
              </div>
            </td>
            <td
              headers="threemonths 3m2019 oe nlatcs"
              className="text-align-right no-border"
              aria-label="negative value"
            >
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss available to common stockholders'][yearEnded2019ColName])})
                </div>
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {isData['Net loss per common share-basic and diluted'] && (
          <tr>
            <th id="nlpcsad" className="text-header no-border">
              Net loss per common share-basic and diluted
            </th>
            <td headers="threemonths  oe nlpcsad" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss per common share-basic and diluted'][threeMonth2020ColName])})
                </div>
              </div>
            </td>
            <td
              headers="threemonths 3m2019 oe nlpcsad"
              className="text-align-right no-border"
              aria-label="negative value"
            >
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss per common share-basic and diluted'][threeMonth2019ColName])})
                </div>
              </div>
            </td>
            <td headers="threemonths  oe nlpcsad" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss per common share-basic and diluted'][yearEnded2020ColName])})
                </div>
              </div>
            </td>
            <td
              headers="threemonths 3m2019 oe nlpcsad"
              className="text-align-right no-border"
              aria-label="negative value"
            >
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss per common share-basic and diluted'][yearEnded2019ColName])})
                </div>
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {isData['Weighted average common shares outstanding-basic and diluted'] && (
          <tr>
            <th id="wacsoad" className="text-header no-border">
              Weighted average common shares outstanding-basic and diluted
            </th>
            <td headers="threemonths  oe wacsoad" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(
                    isData['Weighted average common shares outstanding-basic and diluted'][threeMonth2020ColName]
                  )}
                </div>
              </div>
            </td>
            <td headers="threemonths 3m2019 oe wacsoad" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(
                    isData['Weighted average common shares outstanding-basic and diluted'][threeMonth2019ColName]
                  )}
                </div>
              </div>
            </td>
            <td headers="threemonths  oe wacsoad" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(
                    isData['Weighted average common shares outstanding-basic and diluted'][yearEnded2020ColName]
                  )}
                </div>
              </div>
            </td>
            <td headers="threemonths 3m2019 oe wacsoad" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(
                    isData['Weighted average common shares outstanding-basic and diluted'][yearEnded2019ColName]
                  )}
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  )
}

const GaapTable = data => {
  const gaapData = data.data
  return (
    <>
      <StyledTable
        summary="Column one has the non-GAAP earnings loss source, other columns show the amounts by date period"
        id="ae_revenue"
        className="financial-table"
        border="0"
        cellpadding="0"
        cellspacing="0"
      >
        <thead>
          <tr>
            <td className="no-border" />
            <th colSpan="2" id="threemonths" scope="col" className="text-align-center no-wrap no-border">
              Three months ended
              <br />
              December 31,
            </th>
            <th colSpan="2" id="ninemonths" scope="col" className="text-align-center no-wrap no-border">
              Year ended
              <br />
              December 31,
            </th>
          </tr>
          <tr>
            <td className="no-border" />
            <th id="3m2020" scope="col" colSpan="2" className="no-border no-wrap">
              <div className="subtotal full-width space-between">
                <div style={{ textAlign: 'center' }}>2020</div>
                <div style={{ textAlign: 'center' }}>2019</div>
              </div>
            </th>
            <th id="9m2020" scope="col" colSpan="2" className="no-border no-wrap">
              <div className="subtotal full-width space-between">
                <div style={{ textAlign: 'center' }}>2020</div>
                <div style={{ textAlign: 'center' }}>2019</div>
              </div>
            </th>
          </tr>
          <tr>
            <td colSpan="5" className="text-align-left">
              ( In thousands, except per share data )
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th id="assets" colSpan="5" scope="colgroup" className="large-header">
              Non-GAAP Earnings (Loss) Reconciliation
            </th>
          </tr>
          {gaapData['Net loss (GAAP)'] && (
            <tr>
              <th id="net-loss-gaap" className="text-header">
                Net loss (GAAP)
              </th>
              <td headers="threemonths  net-loss-gaap" className="text-align-right" aria-label="negative value">
                <span className="mr-2">$</span>({formatCurrency(gaapData['Net loss (GAAP)'][threeMonth2020ColName])})
              </td>
              <td headers="threemonths 3m2019 net-loss-gaap" className="text-align-right" aria-label="negative value">
                <span className="mr-2">$</span>({formatCurrency(gaapData['Net loss (GAAP)'][threeMonth2019ColName])})
              </td>
              <td headers="threemonths  net-loss-gaap" className="text-align-right" aria-label="negative value">
                <span className="mr-2">$</span>({formatCurrency(gaapData['Net loss (GAAP)'][yearEnded2020ColName])})
              </td>
              <td headers="threemonths 3m2019 net-loss-gaap" className="text-align-right" aria-label="negative value">
                <span className="mr-2">$</span>({formatCurrency(gaapData['Net loss (GAAP)'][yearEnded2019ColName])})
              </td>
            </tr>
          )}

          {gaapData['Non-cash valuation adjustments to liabilities'] && (
            <tr>
              <th className="text-header">Non-cash valuation adjustments to liabilities</th>
              <td headers="threemonths " className="text-align-right">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities'][threeMonth2020ColName])}
              </td>
              <td headers="threemonths 3m2019" className="text-align-right" aria-label="negative value">
                ({formatCurrency(gaapData['Non-cash valuation adjustments to liabilities'][threeMonth2019ColName])})
              </td>
              <td headers="threemonths " className="text-align-right" aria-label="negative value">
                ({formatCurrency(gaapData['Non-cash valuation adjustments to liabilities'][yearEnded2020ColName])})
              </td>
              <td headers="threemonths 3m2019" className="text-align-right" aria-label="negative value">
                ({formatCurrency(gaapData['Non-cash valuation adjustments to liabilities'][yearEnded2019ColName])})
              </td>
            </tr>
          )}

          {gaapData['Interest expense'] && (
            <tr>
              <th className="text-header">Interest expense</th>
              <td headers="threemonths  interest-expense" className="text-align-right">
                {formatCurrency(gaapData['Interest expense'][threeMonth2020ColName])}
              </td>
              <td headers="threemonths 3m2019 interest-expense" className="text-align-right">
                {formatCurrency(gaapData['Interest expense'][threeMonth2019ColName])}
              </td>
              <td headers="threemonths  interest-expense" className="text-align-right">
                {formatCurrency(gaapData['Interest expense'][yearEnded2020ColName])}
              </td>
              <td headers="threemonths 3m2019 interest-expense" className="text-align-right">
                {formatCurrency(gaapData['Interest expense'][yearEnded2019ColName])}
              </td>
            </tr>
          )}

          {gaapData['Stock-based compensation expense'] && (
            <tr>
              <th className="text-header">Stock-based compensation expense</th>
              <td headers="threemonths  oe" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense'][threeMonth2020ColName])}
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense'][threeMonth2019ColName])}
              </td>
              <td headers="threemonths  oe" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense'][yearEnded2020ColName])}
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense'][yearEnded2019ColName])}
              </td>
            </tr>
          )}

          {gaapData['Severance (1)'] && (
            <tr>
              <th className="text-header">Severance expense (1)</th>
              <td headers="threemonths  oe rad" className="text-align-right no-border">
                {formatCurrency(gaapData['Severance (1)'][threeMonth2020ColName])}
              </td>
              <td headers="threemonths 3m2019 oe rad" className="text-align-right no-border">
                {formatCurrency(gaapData['Severance (1)'][threeMonth2019ColName])}
              </td>
              <td headers="threemonths  oe rad" className="text-align-right no-border">
                {formatCurrency(gaapData['Severance (1)'][yearEnded2020ColName])}
              </td>
              <td headers="threemonths 3m2019 oe rad" className="text-align-right no-border">
                {formatCurrency(gaapData['Severance (1)'][yearEnded2019ColName])}
              </td>
            </tr>
          )}

          {gaapData['Non-GAAP earnings (loss)'] && (
            <tr>
              <th className="text-header">Non-GAAP loss</th>
              <td headers="threemonths  oe" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP earnings (loss)'][threeMonth2020ColName])})
                    </div>
                  </div>
                </div>
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP earnings (loss)'][threeMonth2019ColName])})
                    </div>
                  </div>
                </div>
              </td>
              <td headers="threemonths  oe" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP earnings (loss)'][yearEnded2020ColName])})
                    </div>
                  </div>
                </div>
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP earnings (loss)'][yearEnded2019ColName])})
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          )}

          <tr>
            <td role="presentation" className="spacer" />
          </tr>

          <tr>
            <th id="assets" colSpan="5" scope="colgroup" className="large-header">
              Non-GAAP Earnings (Loss) per Diluted Share Reconciliation
            </th>
          </tr>

          {gaapData['Net loss per common share (GAAP) — diluted'] && (
            <tr>
              <th className="text-header">Net loss per common share (GAAP) — diluted</th>
              <td headers="threemonths  oe" className="text-align-right" aria-label="negative value">
                <div>
                  <span className="mr-2">$</span>(
                  {formatCurrency(gaapData['Net loss per common share (GAAP) — diluted'][threeMonth2020ColName])})
                </div>
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right" aria-label="negative value">
                <div>
                  <span className="mr-2">$</span>(
                  {formatCurrency(gaapData['Net loss per common share (GAAP) — diluted'][threeMonth2019ColName])})
                </div>
              </td>
              <td headers="threemonths  oe" className="text-align-right" aria-label="negative value">
                <div>
                  <span className="mr-2">$</span>(
                  {formatCurrency(gaapData['Net loss per common share (GAAP) — diluted'][yearEnded2020ColName])})
                </div>
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right" aria-label="negative value">
                <div>
                  <span className="mr-2">$</span>(
                  {formatCurrency(gaapData['Net loss per common share (GAAP) — diluted'][yearEnded2019ColName])})
                </div>
              </td>
            </tr>
          )}

          {gaapData['Non-cash valuation adjustments to liabilities - 2'] && (
            <tr>
              <th className="text-header">Non-cash valuation adjustments to liabilities</th>
              <td headers="threemonths  oe" className="text-align-right">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities - 2'][threeMonth2020ColName])}
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right" aria-label="negative value">
                ({formatCurrency(gaapData['Non-cash valuation adjustments to liabilities - 2'][threeMonth2019ColName])})
              </td>
              <td headers="threemonths  oe" className="text-align-right" aria-label="negative value">
                ({formatCurrency(gaapData['Non-cash valuation adjustments to liabilities - 2'][yearEnded2020ColName])})
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right" aria-label="negative value">
                ({formatCurrency(gaapData['Non-cash valuation adjustments to liabilities - 2'][yearEnded2019ColName])})
              </td>
            </tr>
          )}

          {gaapData['Interest expense - 2'] && (
            <tr>
              <th className="text-header">Interest expense</th>
              <td headers="threemonths  oe" className="text-align-right">
                {formatCurrency(gaapData['Interest expense - 2'][threeMonth2020ColName])}
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right">
                {formatCurrency(gaapData['Interest expense - 2'][threeMonth2019ColName])}
              </td>
              <td headers="threemonths  oe" className="text-align-right">
                {formatCurrency(gaapData['Interest expense - 2'][yearEnded2020ColName])}
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right">
                {formatCurrency(gaapData['Interest expense - 2'][yearEnded2019ColName])}
              </td>
            </tr>
          )}

          {gaapData['Stock-based compensation expense - 2'] && (
            <tr>
              <th className="text-header">Stock-based compensation expense</th>
              <td headers="threemonths  oe" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense - 2'][threeMonth2020ColName])}
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense - 2'][threeMonth2019ColName])}
              </td>
              <td headers="threemonths  oe" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense - 2'][yearEnded2020ColName])}
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense - 2'][yearEnded2019ColName])}
              </td>
            </tr>
          )}

          {gaapData['Severance - (1)'] && (
            <tr>
              <th className="text-header">Severance expense (1)</th>
              <td headers="threemonths  oe" className="text-align-right no-border">
                <div>{formatCurrency(gaapData['Severance - (1)'][threeMonth2020ColName])}</div>
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right no-border">
                <div>{formatCurrency(gaapData['Severance - (1)'][threeMonth2019ColName])}</div>
              </td>
              <td headers="threemonths  oe" className="text-align-right no-border">
                <div>{formatCurrency(gaapData['Severance - (1)'][yearEnded2020ColName])}</div>
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right no-border">
                <div>{formatCurrency(gaapData['Severance - (1)'][yearEnded2019ColName])}</div>
              </td>
            </tr>
          )}

          {gaapData['Non-GAAP earnings (loss) per diluted share (2)'] && (
            <tr>
              <th className="text-header" style={{ paddingRight: '0px', fontSize: '95%' }}>
                Non-GAAP loss per diluted share (2)
              </th>
              <td
                headers="threemonths oe"
                className="text-align-right no-border"
                style={{ paddingLeft: '0px' }}
                aria-label="negative value"
              >
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(
                        gaapData['Non-GAAP earnings (loss) per diluted share (2)'][threeMonth2020ColName]
                      )}
                      )
                    </div>
                  </div>
                </div>
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(
                        gaapData['Non-GAAP earnings (loss) per diluted share (2)'][threeMonth2019ColName]
                      )}
                      )
                    </div>
                  </div>
                </div>
              </td>
              <td
                headers="threemonths oe"
                className="text-align-right no-border"
                style={{ paddingLeft: '0px' }}
                aria-label="negative value"
              >
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP earnings (loss) per diluted share (2)'][yearEnded2020ColName])}
                      )
                    </div>
                  </div>
                </div>
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right no-border" aria-label="negative value">
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP earnings (loss) per diluted share (2)'][yearEnded2019ColName])}
                      )
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          )}

          <tr>
            <td role="presentation" className="spacer no-border" />
          </tr>

          {gaapData['Diluted weighted average shares (3)'] && (
            <tr>
              <th className="text-header">Diluted weighted average shares (3)</th>
              <td headers="threemonths  oe" className="text-align-right">
                <div className="total">
                  {formatCurrency(gaapData['Diluted weighted average shares (3)'][threeMonth2020ColName])}
                </div>
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right">
                <div className="total">
                  {formatCurrency(gaapData['Diluted weighted average shares (3)'][threeMonth2019ColName])}
                </div>
              </td>
              <td headers="threemonths  oe" className="text-align-right">
                <div className="total">
                  {formatCurrency(gaapData['Diluted weighted average shares (3)'][yearEnded2020ColName])}
                </div>
              </td>
              <td headers="threemonths 3m2019 oe" className="text-align-right">
                <div className="total">
                  {formatCurrency(gaapData['Diluted weighted average shares (3)'][yearEnded2019ColName])}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </StyledTable>
      <ol style={{ marginTop: '24px', marginLeft: '0px', listStyleType: 'none' }}>
        <li>
          (1) Represents severance expense associated with the move of our technology center to Portland, Oregon, and is
          exclusive of accrued vacation paid upon termination of employment.
        </li>
        <li>
          (2) Non-GAAP earnings per adjusted diluted share for our common stock is computed using the more dilutive of
          the two-class method or the if-converted method.
        </li>
        <li>
          (3) The number of diluted weighted average shares used for this calculation is the same as the weighted
          average common shares outstanding share count when the Company reports a GAAP and non-GAAP net loss.
        </li>
      </ol>
    </>
  )
}

const StyledEarningsReport = styled.div`
  margin: 100px 0 0;
`

const checkExpiredData = timestamp => {
  const dayMs = 1000 * 60 * 60 * 24
  const dayAgo = Date.now() - dayMs
  return timestamp > dayAgo
}

const EarningsReport = () => {
  const [bsData, setBsData] = useState({})
  const [isData, setIsData] = useState({})
  const [gaapData, setGaapData] = useState({})

  const fetchTableData = tableName =>
    new Promise(resolve => {
      const data = {}
      // if no / expired data exists then query AirTable
      AIRTABLE_BASE(tableName)
        .select({
          maxRecords: 1000,
          pageSize: 100,
          view: 'Grid view',
        })
        .eachPage(
          (records, fetchNextPage) => {
            // This function (`page`) will get called for each page of records.
            records.forEach(record => {
              let label = record.fields[labelColName]
              if (data[label]) {
                console.warn('Multiple earnings results with same name: ', label)
                label += '_2'
              }

              if (tableName === 'balance-sheet') {
                data[label] = {
                  [q42020ColName]: record.fields[q42020ColName],
                  [q42019ColName]: record.fields[q42019ColName],
                }
              } else if (tableName === 'income-statement') {
                data[label] = {
                  [yearEnded2020ColName]: record.fields[yearEnded2020ColName],
                  [yearEnded2019ColName]: record.fields[yearEnded2019ColName],
                  [threeMonth2020ColName]: record.fields[threeMonth2020ColName],
                  [threeMonth2019ColName]: record.fields[threeMonth2019ColName],
                }
              } else if (tableName === 'non-gaap') {
                data[label] = {
                  [yearEnded2020ColName]: record.fields[yearEnded2020ColName],
                  [yearEnded2019ColName]: record.fields[yearEnded2019ColName],
                  [threeMonth2020ColName]: record.fields[threeMonth2020ColName],
                  [threeMonth2019ColName]: record.fields[threeMonth2019ColName],
                }
              }
            })

            // To fetch the next page of records, call `fetchNextPage`.
            // If there are more records, `page` will get called again.
            // If there are no more records, `done` will get called.
            fetchNextPage()
          },
          err => {
            // on finish
            if (err) {
              console.error(err)
              resolve({})
            } else {
              resolve(data)
            }
          }
        )
    })

  useEffect(async () => {
    // check local storage and timestamp for existing data
    const ls = getPersistedData()
    const lsEarningsData = ls['earnings-data-q4-2020']

    if (lsEarningsData && checkExpiredData(lsEarningsData.timestamp)) {
      // exists and is not expired
      setBsData(lsEarningsData.bsData)
      setIsData(lsEarningsData.isData)
      setGaapData(lsEarningsData.gaapData)
    } else {
      // Request AirTable base data
      const bsRes = await fetchTableData('balance-sheet')
      const isRes = await fetchTableData('income-statement')
      const gaapRes = await fetchTableData('non-gaap')
      // set data timestamp
      persistData(
        Object.assign(ls, {
          'earnings-data-q4-2020': {
            timestamp: Date.now(),
            bsData: bsRes,
            isData: isRes,
            gaapData: gaapRes,
          },
        })
      )
      setBsData(bsRes)
      setIsData(isRes)
      setGaapData(gaapRes)
    }
  }, [])

  return (
    <StyledEarningsReport>
      <Text center heading3 aria-level="2" role="heading">
        AUDIOEYE, INC.
        <br />
        STATEMENTS OF OPERATIONS (unaudited) <br />
      </Text>
      <Space height={60} />
      <IsTable data={isData} />
      <Space height={100} />
      <Text center heading3 aria-level="2" role="heading">
        AUDIOEYE, INC.
        <br />
        BALANCE SHEETS
        <br />
      </Text>
      <Space height={60} />
      <BsTable data={bsData} />
      <Space height={100} />
      <Text center heading3 aria-level="2" role="heading">
        AUDIOEYE, INC.
        <br />
        RECONCILIATIONS OF GAAP to NON-GAAP FINANCIAL MEASURES (unaudited)
        <br />
      </Text>
      <Space height={60} />
      <GaapTable data={gaapData} />
    </StyledEarningsReport>
  )
}

export default EarningsReport
